import Vue from 'vue'
import {
  format
} from 'date-fns'

// Vue.filter('dateTime', (_date) => {
//   if (Number(_date) === -1) {
//     return '-'
//   }
//   if (_date) {
//     return moment(_date).format('YYYY-MM-DD')
//   }
//   return _date
// })

Vue.filter('format', function (dataStr, pattern = 'yyyy-MM-dd HH:mm:ss') {
  return dataStr ? format(new Date(dataStr)).format(pattern) : ''
})
