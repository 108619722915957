import Vue from 'vue'
import {
  Row,
  Col,
  // Tag,
  // Tabs,
  // Tab,
  // Icon,
    Card,
  Uploader,
  Grid,
  GridItem,
  Toast,
  Button,
  Popup,
  // Pagination,
  Loading,
  // Sticky,
  Cell,
  Dialog,
  // Progress,
  Calendar,
  DropdownMenu,
  DropdownItem,
  // DatetimePicker,
  // List,
  Notify,
  Field,
  Swipe,
  SwipeItem,
  // SwipeCell,
  Checkbox,
  CheckboxGroup,
  // SubmitBar,
  // Collapse,
  // CollapseItem,
  // Empty,
  // Search,
  // ActionSheet,
  // RadioGroup,
  // Radio,
  Tabbar,
  TabbarItem,
  NavBar,
  Form,
  Image
} from 'vant'
export default {
  async init () {
    Vue.use(Row)
    Vue.use(Col)
    // Vue.use(Tag)
    // Vue.use(Tabs)
    // Vue.use(Tab)
    // Vue.use(Icon)
    Vue.use(Card)
    Vue.use(Uploader)
    Vue.use(Grid)
    Vue.use(GridItem)
    Vue.use(Toast)
    Vue.use(Button)
    Vue.use(Popup)
    // Vue.use(Pagination)
    Vue.use(Loading)
    // Vue.use(Sticky)
    Vue.use(Cell)
    Vue.use(Dialog)
    // Vue.use(Progress)
    Vue.use(Calendar)
    Vue.use(DropdownMenu)
    Vue.use(DropdownItem)
    // Vue.use(DatetimePicker)
    // Vue.use(List)
    Vue.use(Notify)
    Vue.use(Field)
    // Vue.use(SwipeCell)
    Vue.use(Swipe);
    Vue.use(SwipeItem);
    Vue.use(Checkbox)
    Vue.use(CheckboxGroup)
    // Vue.use(SubmitBar)
    // Vue.use(Collapse)
    // Vue.use(CollapseItem)
    // Vue.use(Empty)
    // Vue.use(Search)
    // Vue.use(ActionSheet)
    // Vue.use(RadioGroup)
    // Vue.use(Radio)
    Vue.use(Tabbar)
    Vue.use(TabbarItem)
    Vue.use(NavBar)
    Vue.use(Form)
    Vue.use(Image)
  }
}
